import { Header as MantineHeader, Image, ActionIcon, Group, Burger } from "@mantine/core";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { faCircleHalfStroke, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState, useRecoilValue } from "recoil";
import { deviceLayout, isNavbarCollapsed } from "../../store";
import { useUserMetadata } from "../../hooks";

const Header = () => {
  const { preferences, updatePreferences } = useUserMetadata()
  const switchTheme = async () => {
    const newTheme = preferences.theme === "light" ? "dark" : "light";
    await updatePreferences({ theme: newTheme });
  };

  const [navbarCollapsed, setNavbarCollapsed] = useRecoilState(isNavbarCollapsed)
  const toggleNavbar = () => {
    setNavbarCollapsed(val => !val)
  };

  const layout = useRecoilValue(deviceLayout)

  return <MantineHeader height="61" py="xs" px="md" >
    <Group position="apart">
      <Group>
        <Link to="/">
          <Image src={logo} height={40} width={150} />
        </Link>
        {
          layout === "desktop" && <ActionIcon size="lg" onClick={() => setNavbarCollapsed(cur => !cur)}>
            <FontAwesomeIcon icon={navbarCollapsed ? faCaretRight : faCaretLeft} />
          </ActionIcon>
        }
      </Group>
      <Group>
        <ActionIcon size="lg" onClick={switchTheme}>
          <FontAwesomeIcon icon={faCircleHalfStroke} />
        </ActionIcon>
        { layout === "mobile" && <Burger opened={!navbarCollapsed} color={preferences.theme === "light" ? "#868e96" : "#E9ECEF"} onClick={toggleNavbar} /> }
      </Group>
    </Group>
  </MantineHeader>
}

export default Header;
