import { AlertParameterT as AlertParameter, AlertReceiverT, DeactivateAlertReceiverRequest, DeleteAlertReceiverRequest, UpdateAlertReceiverRequest } from "@apis/types";
import axios from "./axios";
import { saveAs } from "file-saver";
import { showNotification } from "@mantine/notifications";

export const getAlertParameters = async (deviceID: string): Promise<AlertParameter[]> => {
  const res = await axios.get<AlertParameter[]>(`alert-parameters/device/${deviceID}`);
  return res.data;
}

export const getAlertParametersOfLandfill = async (landfillID: string): Promise<Record<string, AlertParameter[]>> => {
  const res = await axios.get<Record<string, AlertParameter[]>>(`alert-parameters/landfill/${landfillID}`);
  return res.data;
}

export const exportAlertParameters = async (landfillID: string): Promise<void> => {
  const res = await axios.get(`alert-parameters/landfill/${landfillID}/export`, { responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `alertparameters-${landfillID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const importAlertParameters = async (landfillID: string, file: File): Promise<{ failed: string[] }> => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`alert-parameters/landfill/${landfillID}/import`, formData);
  return res.data;
}

export const getAlertReceiversofLandfill = async (landfillID: string): Promise<AlertReceiverT[]> => {
  const res = await axios.get<AlertReceiverT[]>(`alert-parameters/landfill/${landfillID}/alert-receivers`);
  return res.data;
}

export const updateAlertReceiver = async (landfillID: string, body: UpdateAlertReceiverRequest): Promise<AlertReceiverT> => {
  const res = await axios.post<AlertReceiverT>(`/alert-parameters/landfill/${landfillID}/alert-receivers`, body);
  return res.data;
}

export const deactivateAlertReceiver = async (landfillID: string, body: DeactivateAlertReceiverRequest): Promise<AlertReceiverT> => {
  const res = await axios.put<AlertReceiverT>(`/alert-parameters/landfill/${landfillID}/alert-receivers/deactivate`, body);
  return res.data;
}

export const deleteAlertReceiver = async (landfillID: string, body: DeleteAlertReceiverRequest): Promise<void> => {
  const res = await axios.put(`/alert-parameters/landfill/${landfillID}/alert-receivers/delete`, body);
  return res.data;
}
