import axios from "./axios";
import { CloudConnectorT as CloudConnector, CreateCloudConnectorRequest } from "@apis/types";
import { saveAs } from "file-saver";
import { showNotification } from "@mantine/notifications";

export const getCloudConnectorsOfDevice = async (deviceID: string): Promise<CloudConnector[]> => {
  const res = await axios.get<CloudConnector[]>(`cloud-connectors/${deviceID}`);
  return res.data;
}

export const createCloudConnector = async (deviceID: string, body: CreateCloudConnectorRequest): Promise<CloudConnector> => {
  const res = await axios.post<CloudConnector>(`cloud-connectors/${deviceID}`, body);
  return res.data;
}

export const deleteCloudConnector = async (deviceID: string, cloudConnectorID: string): Promise<CloudConnector> => {
  const res = await axios.delete<CloudConnector>(`cloud-connectors/${deviceID}/${cloudConnectorID}`);
  return res.data;
}

export const getRawCloudConnectorData = async (deviceID: string, cloudConnectorID: string, start: string, end: string) => {
  const res = await axios.get(`/cloud-connectors/${deviceID}/${cloudConnectorID}/raw`, { params: { start, end }, responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `cloud-connector-${cloudConnectorID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}
