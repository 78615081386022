import axios from "./axios";
import {
  CreateLandfillReportReceiverRequest,
  CreateLandfillRequest,
  DeviceSummaryWithMeta,
  LandfillT as Landfill,
  LandfillCharts,
  LandfillReportReceiverT as LandfillReportReceiver,
  LandfillSummary,
  UpdateLandfillRequest,
  ApisRoleDefinition,
  AddLandfillRoleDefinitionRequest,
  AsyncOperationReportType,
  AsyncOperationReportOnly,
  DeviceTotalPerformanceSummary,
  LandfillMonthlyPerformanceAverage
} from "@apis/types";
import { saveAs } from "file-saver";
import { showNotification } from "@mantine/notifications";
import { User } from "@auth0/types";

export const getAllLandfills = async (allowHidden: boolean): Promise<Landfill[]> => {
  const res = await axios.get<Landfill[]>("landfills/all", allowHidden ? { params: { hidden: true } } : {})
  return res.data;
}

export const getLandfill = async (landfillID: string): Promise<Landfill> => {
  const res = await axios.get<Landfill>(`landfills/${landfillID}`)
  return res.data;
}

export const createLandfill = async (body: CreateLandfillRequest): Promise<Landfill> => {
  const res = await axios.post<Landfill>("landfills", body)
  return res.data;
}

export const updateLandfill = async (landfillID: string, body: UpdateLandfillRequest): Promise<Landfill> => {
  const res = await axios.put<Landfill>(`landfills/${landfillID}`, body)
  return res.data;
}

export const deleteLandfill = async (landfillID: string): Promise<Landfill> => {
  const res = await axios.delete<Landfill>(`landfills/${landfillID}`)
  return res.data;
}

export const getLandfillSummary = async (landfillID: string): Promise<LandfillSummary> => {
  const res = await axios.get<LandfillSummary>(`landfills/${landfillID}/summary`)
  return res.data;
}

export const getLandfillCharts = async (landfillID: string, start: string, end: string): Promise<LandfillCharts> => {
  const res = await axios.get<LandfillCharts>(`landfills/${landfillID}/charts`, {
    params: { start, end }
  })
  return res.data;
}

export const getLandfillMonthlyDeviceAverages = async (landfillID: string, startDate: string, endDate: string): Promise<{ deviceTotals: DeviceTotalPerformanceSummary[], landfillPerformanceAverages: LandfillMonthlyPerformanceAverage[] }> => {
  const res = await axios.get<{ deviceTotals: DeviceTotalPerformanceSummary[], landfillPerformanceAverages: LandfillMonthlyPerformanceAverage[] }>(`landfills/${landfillID}/monthly-performance`, {
    params: { startDate, endDate }
  })
  return res.data;
}

export const downloadLandfillAverages = async (landfillID: string, start: string, end: string): Promise<void> => {
  const res = await axios.get(`landfills/${landfillID}/download`, { params: { start, end }, responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `landfill-${landfillID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const getLandfillDeviceSummaries = async (landfillID: string): Promise<DeviceSummaryWithMeta[]> => {
  const res = await axios.get<DeviceSummaryWithMeta[]>(`landfills/${landfillID}/device-summaries`)
  return res.data;
}

export const sampleMultipleDevices = async (landfillID: string, devices: String[]): Promise<{ failed: string[] }> => {
  const res = await axios.post<{ failed: string[] }>(`/landfills/${landfillID}/sample-multiple`, devices )
  return res.data;
}

export const startMultipleCalibration = async (landfillID: string, devices: String[]): Promise<{ failed: string[] }> => {
  const res = await axios.post<{ failed: string[] }>(`/landfills/${landfillID}/calibration-multiple`, devices )
  return res.data;
}

export const exportLandfillSchedulers = async (landfillID: string): Promise<void> => {
  const res = await axios.get(`landfills/${landfillID}/schedulers/export`, { responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `schedulers-${landfillID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const importLandfillSchedulers = async (landfillID: string, file: File): Promise<{ failed: string[] }> => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`landfills/${landfillID}/schedulers/import`, formData);
  return res.data;
}

export const updateKml = async (landfillID: string, file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`landfills/${landfillID}/kml`, formData);
  return res.data;
}

export const getKmlUrl = async (landfillID: string): Promise<string | false> => {
  const res = await axios.get(`landfills/${landfillID}/kml`);
  return res.data
}

export const getLandfillReportByType = async (landfillID: string, startDate: string, endDate: string, reportDate: string, reportType: AsyncOperationReportType): Promise<{ message: string } | { url: string, name: string } | void> => {
  const res = await axios.get<{ message: string } | { url: string, name: string }>(`landfills/${landfillID}/landfill-report`, {
    params: { startDate, endDate, reportDate, reportType }
  });
  if((res.data as { message: string }).message) {
    showNotification({ message: (res.data as { message: string }).message });
  } else {
    const { url, name } = res.data as { url: string, name: string };
    saveAs(url, name);
    showNotification({ message: "Downloaded successfully" });
  }
  return res.data;
}
export const getLandfillReportsByType = async (landfillID: string, reportType: AsyncOperationReportType): Promise<AsyncOperationReportOnly[]> => {
  const res = await axios.get<AsyncOperationReportOnly[]>(`landfills/${landfillID}/landfill-reports`, { params: { reportType } });
  return res.data;
}

export const createLandfillReportByType = async (landfillID: string, startDate: string, endDate: string, reportType: AsyncOperationReportType): Promise<void> => {
  const res = await axios.post<{ message: string } | { url: string, name: string }>(`landfills/${landfillID}/landfill-reports`, { startDate, endDate, reportType });
  if((res.data as { message: string }).message) {
    showNotification({ message: (res.data as { message: string }).message });
  } else {
    const { url, name } = res.data as { url: string, name: string };
    saveAs(url, name);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const exportLandfillDevices = async (landfillID: string): Promise<void> => {
  const res = await axios.get(`landfills/${landfillID}/devices/export`, { responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `devices-${landfillID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const importLandfillDevices = async (landfillID: string, file: File): Promise<{ failed: string[] }> => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`landfills/${landfillID}/devices/import`, formData);
  return res.data;
}

export const importLandfillCustomerData = async (landfillID: string, file: File): Promise<{ message: string }> => {
  const formData = new FormData();
  formData.append("file", file);  
  const res = await axios.post(`landfills/${landfillID}/customer-data/import`, formData);
  return res.data;
}

export const getLandfillRolDefs = async (landfillID: string): Promise<ApisRoleDefinition[]> => {
  const res = await axios.get<ApisRoleDefinition[]>(`landfills/${landfillID}/roles`)
  return res.data;
}

export const addLandfillRoleDefinition = async (landfillID: string, body: AddLandfillRoleDefinitionRequest): Promise<ApisRoleDefinition> => {
  const res = await axios.post<ApisRoleDefinition>(`landfills/${landfillID}/roles`, body)
  return res.data;
}

export const deleteLandfillRoleDefinition = async (landfillID: string, roleDefinitionID: string): Promise<User> => {
  const res = await axios.delete<User>(`landfills/${landfillID}/roles/${roleDefinitionID}`)
  return res.data;
}

export const restoreValvePositions = async (landfillID: string, date: string): Promise<Record<string, { status: boolean, message: string }>> => {
  const res = await axios.post<Record<string, { status: boolean, message: string }>>(`landfills/${landfillID}/restore-valve`, { date })
  return res.data;
}

export const getDailyLandfillReportReceivers = async (landfillID: string): Promise<LandfillReportReceiver[]> => {
  const res = await axios.get<LandfillReportReceiver[]>(`/landfill-reports/receivers/${landfillID}`)
  return res.data;
}

export const addDailyLandfillReportReceiver = async (landfillID: string, body: CreateLandfillReportReceiverRequest): Promise<LandfillReportReceiver> => {
  const res = await axios.post<LandfillReportReceiver>(`/landfill-reports/receivers/${landfillID}`, body)
  return res.data;
}

export const deactivateDailyLandfillReportReceiver = async (landfillID: string, email: string): Promise<LandfillReportReceiver> => {
  const res = await axios.put<LandfillReportReceiver>(`/landfill-reports/receivers/${landfillID}/deactivate/${email}`)
  return res.data;
}

export const deleteDailyLandfillReportReceiver = async (landfillID: string, email: string): Promise<{ message: string }> => {
  const res = await axios.delete<{ message: string }>(`/landfill-reports/receivers/${landfillID}/${email}`)
  return res.data;
}
