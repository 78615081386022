import axios from "./axios";
import { AddNoteRequest, DeviceNoteT, LandfillNoteT, LandfillSummaryTableItem, SetTagsRequest } from "@apis/types";
import { showNotification } from "@mantine/notifications";

export const getAdminTableView = async (): Promise<LandfillSummaryTableItem[]> => {
  const res = await axios.get<LandfillSummaryTableItem[]>("admin/table-view")
  return res.data;
}

export const setTags = async (id: string, body: SetTagsRequest): Promise<string[]> => {
  const res = await axios.put(`admin/tags/${id}`, body)
  return res.data;
}

export const getNotes = async (id: string, type: "landfill" | "device"): Promise<(LandfillNoteT | DeviceNoteT)[]> => {
  const res = await axios.get<(LandfillNoteT | DeviceNoteT)[]>(`admin/notes/${type}/${id}`)
  return res.data;
}

export const addNote = async (id: string, body: AddNoteRequest): Promise<LandfillNoteT | DeviceNoteT> => {
  const res = await axios.post<LandfillNoteT | DeviceNoteT>(`admin/notes/${id}`, body)
  return res.data;
}

export const deleteNote = async (id: string, type: "landfill" | "device"): Promise<string> => {
  const res = await axios.delete<string>(`admin/notes/${type}/${id}`)
  showNotification({ message: res.data });
  return res.data;
}
