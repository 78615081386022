import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";

i18n
  .use(initReactI18next)
  .init({
    resources: { en },
    debug: process.env["NODE_ENV"] === "development",
    fallbackLng: "en",
  });

export default i18n;
