import { Navbar as MantineNavbar, Stack } from "@mantine/core";
import UserInfo from "../UserInfo/UserInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState } from "recoil";
import { isNavbarCollapsed } from "../../store";
import { useEffect, useMemo } from "react";
import MenuItem from "./MenuItem";
import { routes } from "../../pages";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetRolesSelf } from "../../query";
import { ROLE_SCOPES } from "@apis/constants";

const Navbar = () => {
  const location = useLocation();
  const [navbarCollapsed, setNavbarCollapsed] = useRecoilState(isNavbarCollapsed)
  const { t } = useTranslation()
  const { data: userRoles } = useGetRolesSelf()

  const width = useMemo(() => ({
    xs: "100%",
    sm: 85,
    lg: navbarCollapsed ? 85 : 300
  }), [navbarCollapsed]);

  useEffect(() => {
    setNavbarCollapsed(true);
    window.scroll(0, 0);
  }, [location, setNavbarCollapsed]);

  return <MantineNavbar width={width} fixed={false} hidden={navbarCollapsed} hiddenBreakpoint="sm" p="xs">
    <MantineNavbar.Section grow>
      <Stack spacing={0}>
        {
          routes.map((route, i) => route.menu && (!route.scope || (userRoles && userRoles.some(role => ROLE_SCOPES[role.role].includes(route.scope)))) && <MenuItem
            key={`menu-item-${i}`}
            icon={route.icon && <FontAwesomeIcon icon={route.icon} />}
            label={t(route.label)}
            to={route.to}
          />)
        }
      </Stack>
    </MantineNavbar.Section>
    <MantineNavbar.Section>
      <UserInfo />
    </MantineNavbar.Section>
  </MantineNavbar>
}

export default Navbar;
