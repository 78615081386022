import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import * as AlertParameterRequests from "./service/alertParameter";
import {
  AlertParameterT, AlertReceiverT,
  DeactivateAlertReceiverRequest,
  DeleteAlertReceiverRequest,
  UpdateAlertReceiverRequest
} from "@apis/types";
import { queryClient } from "./index";

////////// QUERIES //////////
export const useGetDeviceAlertParameters = (deviceID: string, options?: Omit<UseQueryOptions<AlertParameterT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-alert-parameters", deviceID],
  queryFn: () => AlertParameterRequests.getAlertParameters(deviceID),
  ...options
});

export const useGetLandfillAlertParameters = (landfillID: string, options?: Omit<UseQueryOptions<Record<string, AlertParameterT[]>>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-alert-parameters", landfillID],
  queryFn: () => AlertParameterRequests.getAlertParametersOfLandfill(landfillID),
  ...options
});

export const useGetAlertReceiversOfLandfill = (landfillID: string, options?: Omit<UseQueryOptions<AlertReceiverT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-alert-receivers", landfillID],
  queryFn: () => AlertParameterRequests.getAlertReceiversofLandfill(landfillID),
  ...options
});



////////// MUTATIONS //////////
export const useExportAlertParameters = (landfillID: string, options?: UseMutationOptions<void>) => useMutation({
  mutationKey: ["export-alert-parameters", landfillID],
  mutationFn: () => AlertParameterRequests.exportAlertParameters(landfillID),
  ...options
});

export const useImportAlertParameters = (landfillID: string, options?: UseMutationOptions<{ failed: string[] }, Error, File>) => useMutation({
  mutationKey: ["import-alert-parameters", landfillID],
  mutationFn: (file) => AlertParameterRequests.importAlertParameters(landfillID, file),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-alert-parameters", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateAlertReceiver = (landfillID: string, options?: UseMutationOptions<AlertReceiverT, Error, UpdateAlertReceiverRequest>) => useMutation({
  mutationKey: ["update-alert-receiver", landfillID],
  mutationFn: (body) => AlertParameterRequests.updateAlertReceiver(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-alert-receivers", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeactivateAlertReceiver = (landfillID: string, options?: UseMutationOptions<AlertReceiverT, Error, DeactivateAlertReceiverRequest>) => useMutation({
  mutationKey: ["deactivate-alert-receiver", landfillID],
  mutationFn: (body) => AlertParameterRequests.deactivateAlertReceiver(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-alert-receivers", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteAlertReceiver = (landfillID: string, options?: UseMutationOptions<void, Error, DeleteAlertReceiverRequest>) => useMutation({
  mutationKey: ["delete-alert-receiver", landfillID],
  mutationFn: (body) => AlertParameterRequests.deleteAlertReceiver(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-alert-receivers", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
