import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import * as AdminRequests from "./service/admin";
import { DeviceNoteT, LandfillNoteT, LandfillSummaryTableItem } from "@apis/types";
import { queryClient } from "./index";

////////// QUERIES //////////
export const useGetAdminTableView = (options?: Omit<UseQueryOptions<LandfillSummaryTableItem[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["admin-table-view"],
  queryFn:() => AdminRequests.getAdminTableView(),
  ...options
});

export const useGetNotes = (id: string, type: "landfill" | "device", options?: Omit<UseQueryOptions<(LandfillNoteT | DeviceNoteT)[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey:   ["notes", type, id],
  queryFn:() => AdminRequests.getNotes(id, type),
  ...options
});

////////// MUTATIONS //////////
export const useUpdateTags = (id: string, type: "landfill" | "device", options?: UseMutationOptions<string[], Error, string[]>) => useMutation({
  mutationKey: ["update-tags", id],
  mutationFn: (tags) => AdminRequests.setTags(id, { type, tags }),
  ...options,
  onSuccess: (...args) => {
    if (type === "landfill") {
      queryClient.setQueryData(["admin-table-view"], (oldData: LandfillSummaryTableItem[]) => oldData.map(lfSum => lfSum.id === id ? { ...lfSum, tags: args[0] } : lfSum))
    }
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useAddNote = (id: string, type: "landfill" | "device", options?: UseMutationOptions<LandfillNoteT | DeviceNoteT, Error, string>) => useMutation({
  mutationKey: ["add-note", type, id],
  mutationFn: (content) => AdminRequests.addNote(id, { type, content }),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["notes", type, id], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteNote = (refetchID: string, type: "landfill" | "device", options?: UseMutationOptions<string, Error, string>) => useMutation({
  mutationKey: ["notes", type, refetchID],
  mutationFn: (noteID) => AdminRequests.deleteNote(noteID, type),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["notes", type, refetchID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
