import { useMutation, useQuery, UseQueryOptions, UseMutationOptions, useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import * as ControlAlgorithmSettingsRequests from "./service/controlAlgorithmSettings";
import {
  ControlAlgorithmKey, ControlAlgorithmLog,
  LandfillControlAlgorithmConfigs, ControlAlgorithmSettingsT,
} from "@apis/types";
import { queryClient } from "./index";

////////// QUERIES //////////
export const useGetDeviceControlAlgorithmSettings = (deviceID: string, options?: Omit<UseQueryOptions<ControlAlgorithmSettingsT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-control-algorithm-settings", deviceID],
  queryFn: () => ControlAlgorithmSettingsRequests.getControlAlgorithmSettingsOfDevice(deviceID),
  ...options
});

export const useGetControlAlgorithmSettingsByParameter = (deviceID: string, parameter: ControlAlgorithmKey, options?: Omit<UseQueryOptions<ControlAlgorithmSettingsT>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["control-algorithm-settings-by-parameter", deviceID, parameter],
  queryFn: () => ControlAlgorithmSettingsRequests.getControlAlgorithmSettingsByParameter(deviceID, parameter),
  ...options
});

export const useGetLandfillControlAlgorithmSettings = (landfillID: string, options?: Omit<UseQueryOptions<{ settings: Record<string, ControlAlgorithmSettingsT[]>, ch4Stopped: string[] }>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-control-algorithm-settings", landfillID],
  queryFn: () => ControlAlgorithmSettingsRequests.getControlAlgorithmSettingsOfLandfill(landfillID),
  ...options
});

export const useGetLandfillAlgorithmConfigs = (landfillID: string, options?: Omit<UseQueryOptions<LandfillControlAlgorithmConfigs>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-control-algorithm-configs", landfillID],
  queryFn: () => ControlAlgorithmSettingsRequests.getControlAlgorithmConfigsOfLandfill(landfillID),
  ...options
});

export const useGetControlAlgorithmLogs = (deviceID: string, options?: Omit<UseInfiniteQueryOptions<ControlAlgorithmLog[], Error, ControlAlgorithmLog[], ControlAlgorithmLog[], string[], string>, "queryKey" | "queryFn">) => useInfiniteQuery({
  queryKey: ["control-algorithm-logs", deviceID],
  queryFn: ({ pageParam }) => ControlAlgorithmSettingsRequests.getControlAlgorithmLogs(deviceID, pageParam),
  select: (data) => data.pages.flat(),
  getNextPageParam: (lastPage) =>  lastPage?.length ? lastPage[lastPage.length - 1].date : null,
  ...options
});


////////// MUTATIONS //////////
export const useUpdateControlAlgorithmSettings = (deviceID: string, landfillID: string, parameter: ControlAlgorithmKey, options?: UseMutationOptions<ControlAlgorithmSettingsT, Error, boolean | number | { min: number, max: number }>) => useMutation({
  mutationKey: ["update-control-algorithm-settings", deviceID],
  mutationFn: (body) => ControlAlgorithmSettingsRequests.updateControlAlgorithmSettings(deviceID, { parameter, value: body }),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-control-algorithm-settings", landfillID], exact: true })
    queryClient.refetchQueries({ queryKey: ["device-control-algorithm-settings", deviceID], exact: true })
    queryClient.refetchQueries({ queryKey: ["control-algorithm-settings-by-parameter", deviceID, parameter], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useSetAlgorithmConfigsOfLandfill = (landfillID: string, options?: UseMutationOptions<LandfillControlAlgorithmConfigs, Error, LandfillControlAlgorithmConfigs>) => useMutation({
  mutationKey: ["set-control-algorithm-configs-of-landfill", landfillID],
  mutationFn: (body) => ControlAlgorithmSettingsRequests.setAlgorithmConfigsOfLandfill(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-control-algorithm-configs", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useExportControlAlgorithmSettings = (landfillID: string, options?: UseMutationOptions<void>) => useMutation({
  mutationKey: ["export-control-algorithm-settings", landfillID],
  mutationFn: () => ControlAlgorithmSettingsRequests.exportControlAlgorithmSettings(landfillID),
  ...options
});

export const useImportControlAlgorithmSettings = (landfillID: string, options?: UseMutationOptions<{ failed: string[] }, Error, File>) => useMutation({
  mutationKey: ["import-control-algorithm-settings", landfillID],
  mutationFn: (file) => ControlAlgorithmSettingsRequests.importControlAlgorithmSettings(landfillID, file),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-control-algorithm-settings", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
