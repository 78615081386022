export const DEFAULT_SCHEDULER = {
  active: false,
  sampleType: "GS_1",
  interval: "0 */3 * * *",
  leakTest: false
};

export const WIRED_INTERVALS: number[] = [5, 10, 15, 20, 30]
export const WIRED_INTERVALS_ENUM = WIRED_INTERVALS.map(i => `*/${i} * * * *`) as [string, ...string[]];
export const WIRED_INTERVAL_OPTIONS = WIRED_INTERVALS.map(i => ({ label: `${i} minutes`, value: `*/${i} * * * *` })) as { label: string, value: string }[];

export const WIRELESS_INTERVALS: number[] = [1,2,3,4,6,8,12,24];
export const WIRELESS_INTERVALS_ENUM = WIRELESS_INTERVALS.map(i => `0 */${i} * * *`) as [string, ...string[]];
export const WIRELESS_INTERVAL_OPTIONS = WIRELESS_INTERVALS.map(i => ({ label: `${i} hours`, value: `0 */${i} * * *` })) as { label: string, value: string }[];

export const SCHEDULER_SAMPLE_TYPES = {
  GS_1: "Normal",
  AS_1: "Available Port",
}
