import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import * as CompanyRequests from "./service/company";
import {
  ApisCompanyUser,
  ApisRoleDefinition,
  CompanyT,
  CreateCompanyRequest,
  CreateUserRequest,
  UpdateCompanyRequest
} from "@apis/types";
import { queryClient } from "./index";
import { User } from "@auth0/types";

////////// QUERIES //////////
export const useGetCompanies = (options?: Omit<UseQueryOptions<CompanyT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["companies"],
  queryFn: () => CompanyRequests.getAllCompanies(),
  ...options
});

export const useGetCompany = (companyID: string, options?: Omit<UseQueryOptions<CompanyT>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["company", companyID],
  queryFn: () => CompanyRequests.getCompany(companyID),
  ...options
});

export const useGetCompanyUsers = (companyID: string, options?: Omit<UseQueryOptions<ApisCompanyUser[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["company-users", companyID],
  queryFn: () => CompanyRequests.getCompanyUsers(companyID),
  ...options
});

export const useGetCompanyAdmins = (companyID: string, options?: Omit<UseQueryOptions<ApisRoleDefinition[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["company-admins", companyID],
  queryFn: () => CompanyRequests.getCompanyAdmins(companyID),
  ...options
});



////////// MUTATIONS //////////
export const useCreateCompany = (options?: UseMutationOptions<CompanyT, Error, CreateCompanyRequest>) => useMutation({
  mutationKey: ["create-company"],
  mutationFn: (body) => CompanyRequests.createCompany(body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["companies"], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateCompany = (companyID: string, options?: UseMutationOptions<CompanyT, Error, UpdateCompanyRequest>) => useMutation({
  mutationKey: ["update-company", companyID],
  mutationFn: (body) => CompanyRequests.updateCompany(companyID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["companies"], exact: true })
    queryClient.refetchQueries({ queryKey: ["company", companyID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteCompany = (companyID: string, options?: UseMutationOptions<CompanyT>) => useMutation({
  mutationKey: ["delete-company", companyID],
  mutationFn: () => CompanyRequests.deleteCompany(companyID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["companies"], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useGenerateAPIToken = (companyID: string, options?: UseMutationOptions<{ token: string }>) => useMutation({
  mutationKey: ["generate-api-token", companyID],
  mutationFn: () => CompanyRequests.generateAPIToken(companyID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["company", companyID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useCompanyInviteUser = (companyID: string, options?: UseMutationOptions<ApisCompanyUser, Error, CreateUserRequest>) => useMutation({
  mutationKey: ["invite-user", companyID],
  mutationFn: (body) => CompanyRequests.inviteUser(companyID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["company-users", companyID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useCompanyDeleteUser = (companyID: string, options?: UseMutationOptions<User, Error, string>) => useMutation({
  mutationKey: ["delete-user", companyID],
  mutationFn: (userid) => CompanyRequests.deleteUserFromCompany(companyID, userid),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["company-users", companyID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useAddCompanyAdmin = (companyID: string, options?: UseMutationOptions<ApisRoleDefinition, Error, string>) => useMutation({
  mutationKey: ["add-company-admin", companyID],
  mutationFn: (userid) => CompanyRequests.addCompanyAdmin(companyID, userid),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["company-admins", companyID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useRevokeCompanyAdmin = (companyID: string, options?: UseMutationOptions<User, Error, string>) => useMutation({
  mutationKey: ["revoke-company-admin", companyID],
  mutationFn: (userid) => CompanyRequests.revokeCompanyAdmin(companyID, userid),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["company-admins", companyID],exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
