export const environment = {
  APP_VERSION: process.env["NX_APP_VERSION"] || "dev",
  AUTH0_DOMAIN: process.env["NX_AUTH0_DOMAIN"] || "",
  AUTH0_CLIENT_ID: process.env["NX_AUTH0_CLIENT_ID"] || "",
  AUTH0_REDIRECT_URL: process.env["NX_AUTH0_REDIRECT_URL"] || "",
  AUTH0_AUDIENCE: process.env["NX_AUTH0_AUDIENCE"] || "",
  API_URL: process.env["NX_API_URL"] || "",
  GOOGLE_MAPS_API_KEY: process.env["NX_GOOGLE_MAPS_API_KEY"] || "",
  SENTRY_DSN: process.env["NX_SENTRY_DSN"],
  APP_ENVIRONMENT: process.env["NX_APP_ENVIRONMENT"] || "development",
};
