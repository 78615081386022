import { ThemeIcon, NavLink, Tooltip, createStyles } from "@mantine/core";
import { useRecoilValue } from "recoil";
import { deviceLayout, isNavbarCollapsed } from "../../store";
import { useMemo, ReactNode } from "react";
import { useMatch, NavLink as RRNavLink } from "react-router-dom";

interface MenuItemProps {
  label: string;
  to: string;
  icon?: ReactNode;
}

interface StyleProps {
  collapsed: boolean;
}

const useStyles = createStyles((theme, { collapsed }: StyleProps) => ({
  root: {
    padding: `${theme.spacing.md}`,
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
  },
  icon: {
    marginRight: collapsed ? "auto" : theme.spacing.md,
    marginLeft: "auto"
  },
  body: {
    display: collapsed ? "none" : "block"
  }
}))

const MenuItem = ({ label, to, icon }: MenuItemProps) => {
  const match = useMatch(to);
  const layout = useRecoilValue(deviceLayout)
  const navbarCollapsed = useRecoilValue(isNavbarCollapsed)
  const collapsed = useMemo(() => layout === "tablet" || navbarCollapsed, [layout, navbarCollapsed]);
  const { classes } = useStyles({ collapsed });
  return <Tooltip label={label} position="right" disabled={!collapsed} withArrow>
    <NavLink
      label={!collapsed && label}
      icon={icon && <ThemeIcon variant="light" size="lg">{icon}</ThemeIcon>}
      classNames={classes}
      active={!!match}
      component={RRNavLink}
      to={to}
    />
  </Tooltip>
}

export default MenuItem;
