import { DeviceConfigDetails, DeviceConfigType, DeviceType, DeviceTypeDetails } from "@apis/types";

export const DEVICE_TYPE = {
  SMART_WELL: "Smart Well",
  WELL_MONITOR: "Well Monitor",
  FLARE_MONITOR: "Flare Monitor",
  HEADER_MONITOR: "Header Monitor",
  HEADER_CONTROLLER: "Header Controller",
  PRESSURE_MONITOR: "Pressure Monitor",
  WEATHER_STATION: "Weather Station"
} as const
export const DEVICE_TYPES = [
  DEVICE_TYPE.SMART_WELL,
  DEVICE_TYPE.WELL_MONITOR,
  DEVICE_TYPE.FLARE_MONITOR,
  DEVICE_TYPE.HEADER_MONITOR,
  DEVICE_TYPE.HEADER_CONTROLLER,
  DEVICE_TYPE.PRESSURE_MONITOR,
  DEVICE_TYPE.WEATHER_STATION
] as const;

export const DEVICE_SAMPLE_TYPE = {
  GS_1: "GS_1",
  AS_1: "AS_1",
  C8: "C8",
  C7: "C7"
} as const
export const ALL_DEVICE_SAMPLE_TYPES = [
  DEVICE_SAMPLE_TYPE.GS_1,
  DEVICE_SAMPLE_TYPE.AS_1,
  DEVICE_SAMPLE_TYPE.C8,
  DEVICE_SAMPLE_TYPE.C7
] as const

export const DEVICE_SAMPLE_TYPES = [
  DEVICE_SAMPLE_TYPE.GS_1,
  DEVICE_SAMPLE_TYPE.AS_1
] as const

export const DEVICE_CALIBRATION_TYPES = [
  DEVICE_SAMPLE_TYPE.C8,
  DEVICE_SAMPLE_TYPE.C7
] as const

export const FLOW_METER_TYPE = {
  VENTURI: "Venturi",
  ORIFICE_PLATE: "Orifice Plate"
} as const
export const FLOW_METER_TYPES = [
  FLOW_METER_TYPE.VENTURI,
  FLOW_METER_TYPE.ORIFICE_PLATE
] as const

export const DEVICE_CONFIG_TYPE = {
  RESTRICTION_SIZE: "restrictionSize",
  PIPE_DIAMETER: "pipeDiameter",
  CENTER_VELOCITY_ESTIMATE_FACTOR: "centerVelocityEstimateFactor",
  INCLUDED_SUMMARY: "includedSummary",
  LIQUID_LEVEL: "liquidLevel",
  FLOW_METER: "flowMeter",
  WIRED: "wired",
  SECONDARY_STATIC_PRESSURE_PORT: "secondaryStaticPressurePort"
} as const;

export const DEVICE_CONFIG_TYPES = [
  DEVICE_CONFIG_TYPE.RESTRICTION_SIZE,
  DEVICE_CONFIG_TYPE.PIPE_DIAMETER,
  DEVICE_CONFIG_TYPE.CENTER_VELOCITY_ESTIMATE_FACTOR,
  DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY,
  DEVICE_CONFIG_TYPE.LIQUID_LEVEL,
  DEVICE_CONFIG_TYPE.FLOW_METER,
  DEVICE_CONFIG_TYPE.WIRED,
  DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT
] as const;

export const DEVICE_CONFIG_DETAILS: Record<DeviceConfigType, DeviceConfigDetails> = {
  [DEVICE_CONFIG_TYPE.RESTRICTION_SIZE]: {
    type: "number",
    historical: true,
    mutable: true,
    I18NKey: "DEVICE_ATTR.RESTRICTION_SIZE"
  },
  [DEVICE_CONFIG_TYPE.PIPE_DIAMETER]: {
    type: "number",
    historical: false,
    mutable: false,
    I18NKey: "DEVICE_ATTR.PIPE_DIAMETER"
  },
  [DEVICE_CONFIG_TYPE.CENTER_VELOCITY_ESTIMATE_FACTOR]: {
    type: "number",
    defaultValue: 0.8,
    historical: false,
    mutable: true,
    I18NKey: "DEVICE_ATTR.CENTER_VELOCITY_ESTIMATE_FACTOR"
  },
  [DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY]: {
    type: "boolean",
    defaultValue: true,
    historical: false,
    mutable: true,
    I18NKey: "DEVICE_ATTR.INCLUDED_SUMMARY"
  },
  [DEVICE_CONFIG_TYPE.LIQUID_LEVEL]: {
    type: "boolean",
    defaultValue: false,
    historical: false,
    mutable: true,
    I18NKey: "DEVICE_ATTR.LIQUID_LEVEL"
  },
  [DEVICE_CONFIG_TYPE.FLOW_METER]: {
    type: "string",
    defaultValue: FLOW_METER_TYPE.VENTURI,
    historical: false,
    mutable: true,
    I18NKey: "DEVICE_ATTR.FLOW_METER"
  },
  [DEVICE_CONFIG_TYPE.WIRED]: {
    type: "boolean",
    defaultValue: false,
    historical: false,
    mutable: false,
    I18NKey: "DEVICE_ATTR.WIRED"
  },
  [DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT]: {
    type: "boolean",
    defaultValue: false,
    historical: false,
    mutable: false,
    I18NKey: "DEVICE_ATTR.SECONDARY_STATIC_PRESSURE_PORT"
  }
}

export const DEVICE_FEATURE = {
  SAMPLE: "SAMPLE",
  CALIBRATION: "CALIBRATION",
  CONTROL_ALGORITHM: "CONTROL_ALGORITHM",
  ALERT_PARAMETERS: "ALERT_PARAMETERS",
  CLOUD_CONNECTORS: "CLOUD_CONNECTORS",
  DATA_MANAGER: "DATA_MANAGER",
  DEVICE_GROUP: "DEVICE_GROUP",
  AVERAGED_DATA: "AVERAGED_DATA",
  VALVE_ADJUSTMENT: "VALVE_ADJUSTMENT",
  WELLHEAD_LEAK_TEST: "WELLHEAD_LEAK_TEST"
} as const;

export const DEVICE_FEATURES = [
  DEVICE_FEATURE.SAMPLE,
  DEVICE_FEATURE.CALIBRATION,
  DEVICE_FEATURE.CONTROL_ALGORITHM,
  DEVICE_FEATURE.ALERT_PARAMETERS,
  DEVICE_FEATURE.CLOUD_CONNECTORS,
  DEVICE_FEATURE.DATA_MANAGER,
  DEVICE_FEATURE.DEVICE_GROUP,
  DEVICE_FEATURE.VALVE_ADJUSTMENT,
  DEVICE_FEATURE.WELLHEAD_LEAK_TEST
]

export const DEVICE_TYPE_DETAILS: Record<DeviceType, DeviceTypeDetails> = {
  [DEVICE_TYPE.SMART_WELL]: {
    configs: [
      DEVICE_CONFIG_TYPE.FLOW_METER,
      DEVICE_CONFIG_TYPE.RESTRICTION_SIZE,
      DEVICE_CONFIG_TYPE.PIPE_DIAMETER,
      DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY,
      DEVICE_CONFIG_TYPE.LIQUID_LEVEL,
      DEVICE_CONFIG_TYPE.WIRED,
      DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT
    ],
    features: [
      DEVICE_FEATURE.SAMPLE,
      DEVICE_FEATURE.CALIBRATION,
      DEVICE_FEATURE.CONTROL_ALGORITHM,
      DEVICE_FEATURE.ALERT_PARAMETERS,
      DEVICE_FEATURE.DATA_MANAGER,
      DEVICE_FEATURE.DEVICE_GROUP,
      DEVICE_FEATURE.VALVE_ADJUSTMENT,
      DEVICE_FEATURE.WELLHEAD_LEAK_TEST
    ]
  },
  [DEVICE_TYPE.WELL_MONITOR]: {
    configs: [
      DEVICE_CONFIG_TYPE.FLOW_METER,
      DEVICE_CONFIG_TYPE.RESTRICTION_SIZE,
      DEVICE_CONFIG_TYPE.PIPE_DIAMETER,
      DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY,
      DEVICE_CONFIG_TYPE.LIQUID_LEVEL,
      DEVICE_CONFIG_TYPE.WIRED,
      DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT
    ],
    features: [
      DEVICE_FEATURE.SAMPLE,
      DEVICE_FEATURE.CALIBRATION,
      DEVICE_FEATURE.ALERT_PARAMETERS,
      DEVICE_FEATURE.DATA_MANAGER,
      DEVICE_FEATURE.DEVICE_GROUP
    ]
  },
  [DEVICE_TYPE.FLARE_MONITOR]: {
    configs: [
      DEVICE_CONFIG_TYPE.PIPE_DIAMETER,
      DEVICE_CONFIG_TYPE.CENTER_VELOCITY_ESTIMATE_FACTOR,
      DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY,
      DEVICE_CONFIG_TYPE.LIQUID_LEVEL,
      DEVICE_CONFIG_TYPE.WIRED,
      DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT
    ],
    features: [
      DEVICE_FEATURE.SAMPLE,
      DEVICE_FEATURE.CALIBRATION,
      DEVICE_FEATURE.ALERT_PARAMETERS,
      DEVICE_FEATURE.DATA_MANAGER,
      DEVICE_FEATURE.DEVICE_GROUP
    ]
  },
  [DEVICE_TYPE.HEADER_MONITOR]: {
    configs: [
      DEVICE_CONFIG_TYPE.PIPE_DIAMETER,
      DEVICE_CONFIG_TYPE.CENTER_VELOCITY_ESTIMATE_FACTOR,
      DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY,
      DEVICE_CONFIG_TYPE.LIQUID_LEVEL,
      DEVICE_CONFIG_TYPE.WIRED,
      DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT
    ],
    features: [
      DEVICE_FEATURE.SAMPLE,
      DEVICE_FEATURE.CALIBRATION,
      DEVICE_FEATURE.ALERT_PARAMETERS,
      DEVICE_FEATURE.DATA_MANAGER,
      DEVICE_FEATURE.DEVICE_GROUP,
      DEVICE_FEATURE.CLOUD_CONNECTORS
    ]
  },
  [DEVICE_TYPE.HEADER_CONTROLLER]: {
    configs: [
      DEVICE_CONFIG_TYPE.PIPE_DIAMETER,
      DEVICE_CONFIG_TYPE.CENTER_VELOCITY_ESTIMATE_FACTOR,
      DEVICE_CONFIG_TYPE.INCLUDED_SUMMARY,
      DEVICE_CONFIG_TYPE.LIQUID_LEVEL,
      DEVICE_CONFIG_TYPE.WIRED,
      DEVICE_CONFIG_TYPE.SECONDARY_STATIC_PRESSURE_PORT
    ],
    features: [
      DEVICE_FEATURE.SAMPLE,
      DEVICE_FEATURE.CALIBRATION,
      DEVICE_FEATURE.ALERT_PARAMETERS,
      DEVICE_FEATURE.DATA_MANAGER,
      DEVICE_FEATURE.DEVICE_GROUP,
      DEVICE_FEATURE.VALVE_ADJUSTMENT
    ]
  },
  [DEVICE_TYPE.PRESSURE_MONITOR]: {
    configs: [],
    features: [
      DEVICE_FEATURE.DATA_MANAGER,
    ]
  },
  [DEVICE_TYPE.WEATHER_STATION]: {
    configs: [],
    features: [
      DEVICE_FEATURE.DATA_MANAGER
    ]
  },
}
