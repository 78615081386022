import { LineController } from "chart.js";

class CustomLineChart extends LineController {
  override draw() {
    super.draw();
    const meta = this.getMeta();
    const chart = meta.controller.chart;
    const tooltip = chart.tooltip;
    if (tooltip && tooltip.opacity > 0) {
      const x = tooltip.caretX
      //const { x } = tooltip.tooltipPosition();
      const { bottom, top } = chart.chartArea;
      const ctx = meta.controller.chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, bottom);
      ctx.lineWidth = 1;
      ctx.lineTo(x, top);
      ctx.strokeStyle = "rgba(192, 192, 192, 0.6)";
      ctx.stroke();
      ctx.restore();
    }
  }
}

CustomLineChart.id = "customLineChart";
CustomLineChart.defaults = LineController.defaults;

export default CustomLineChart;
