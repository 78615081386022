export const DEFAULT_CALIBRATION = {
  active: false,
  interval: 7,
  zeroCalibration: false,
  hours: [9],
  tempTrigger: false,
  tempTriggerThreshold: 60,
  barPressTrigger: false,
  barPressTriggerThreshold: 75,
  dailyLimit: 2
};

export const CALIBRATION_INTERVALS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export const DEFAULT_CANISTER_CONTENT = {
  CH4: 50.0,
  CO2: 35.0,
  O2: 0.0
}
