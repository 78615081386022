import { UserRole, UserScope } from "@apis/types";

export const ROLE = {
  APIS_ADMIN: "apis-admin",
  APIS_TECHNICIAN: "apis-technician",
  COMPANY_ADMIN: "company-admin",
  LANDFILL_MANAGER: "landfill-manager",
  LANDFILL_TECHNICIAN: "landfill-technician",
  LANDFILL_USER: "landfill-user",
} as const;

export const ROLES = [
  ROLE.APIS_ADMIN,
  ROLE.APIS_TECHNICIAN,
  ROLE.COMPANY_ADMIN,
  ROLE.LANDFILL_MANAGER,
  ROLE.LANDFILL_TECHNICIAN,
  ROLE.LANDFILL_USER
] as const

export const ADMIN_ROLES = [
  ROLE.APIS_ADMIN,
  ROLE.APIS_TECHNICIAN,
] as const

export const CUSTOMER_ROLES = [
  ROLE.COMPANY_ADMIN,
  ROLE.LANDFILL_MANAGER,
  ROLE.LANDFILL_TECHNICIAN,
  ROLE.LANDFILL_USER
] as const

export const LANDFILL_ROLES = [
  ROLE.LANDFILL_MANAGER,
  ROLE.LANDFILL_TECHNICIAN,
  ROLE.LANDFILL_USER
] as const

export const USER_SCOPES = [
  "create:company", "create:landfill", "create:device", "create:performance-dashboard",
  "read:company", "read:landfill", "read:device", "read:performance-dashboard",
  "update:company", "update:landfill", "update:device", "update:performance-dashboard",
  "delete:company", "delete:landfill", "delete:device", "delete:performance-dashboard",
  "logs:device",
  "manage:company-users", "manage:landfill-users",
  "admin:all"
] as const;

export const ROLE_SCOPES: Record<UserRole, UserScope[]> = {
  [ROLE.APIS_ADMIN]: USER_SCOPES as unknown as UserScope[],
  [ROLE.APIS_TECHNICIAN]: [
    "create:landfill", "create:device",
    "read:company", "read:landfill", "read:device",
    "update:landfill", "update:device",
    "delete:landfill", "delete:device",
    "logs:device",
    "admin:all"
  ],
  [ROLE.COMPANY_ADMIN]: [
    "create:landfill", "create:device",
    "read:company", "read:landfill", "read:device",
    "update:company", "update:landfill", "update:device",
    "delete:landfill", "delete:device",
    "manage:company-users", "manage:landfill-users"
  ],
  [ROLE.LANDFILL_MANAGER]: [
    "create:device",
    "read:company", "read:landfill", "read:device",
    "update:landfill", "update:device",
    "delete:device",
    "manage:landfill-users"
  ],
  [ROLE.LANDFILL_TECHNICIAN]: [
    "create:device",
    "read:company", "read:landfill", "read:device",
    "update:device",
  ],
  [ROLE.LANDFILL_USER]: [
    "read:company", "read:landfill", "read:device",
  ]
}
