import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import * as CloudConnectorRequests from "./service/cloudConnector";
import { CloudConnectorT, CreateCloudConnectorRequest } from "@apis/types";
import { queryClient } from "./index";

////////// QUERIES //////////
export const useGetCloudConnectorOfDevice = (deviceID: string, options?: Omit<UseQueryOptions<CloudConnectorT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-cloud-connectors", deviceID],
  queryFn: () => CloudConnectorRequests.getCloudConnectorsOfDevice(deviceID),
  ...options
});


////////// MUTATIONS //////////
export const useCreateCloudConnector = (deviceID: string, options?: UseMutationOptions<CloudConnectorT, Error, CreateCloudConnectorRequest>) => useMutation({
  mutationKey: ["create-cloud-connector", deviceID],
  mutationFn: (body) => CloudConnectorRequests.createCloudConnector(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-cloud-connectors", deviceID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteCloudConnector = (deviceID: string, options?: UseMutationOptions<CloudConnectorT, Error, string>) => useMutation({
  mutationKey: ["delete-cloud-connector", deviceID],
  mutationFn: (cloudConnectorID) => CloudConnectorRequests.deleteCloudConnector(deviceID, cloudConnectorID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-cloud-connectors", deviceID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDownloadRawCloudConnectorData = (deviceID: string, options?: UseMutationOptions<void, Error, { connectorID: string, start: string, end: string }>) => useMutation({
  mutationKey: ["download-cc-raw-samples", deviceID],
  mutationFn: ({ connectorID, start, end }) => CloudConnectorRequests.getRawCloudConnectorData(deviceID, connectorID, start, end),
  ...options
});
