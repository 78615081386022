import { atom, selector } from "recoil";

export const width = atom({
  key: "layout",
  default: window.innerWidth
});

export const deviceLayout = selector({
  key: "deviceLayout",
  get: ({ get }) => {
    const appWidth = get(width);
    if (appWidth < 768) return "mobile";
    if (appWidth < 1280) return "tablet";
    return "desktop";
  }
})

export const isNavbarCollapsed = atom({
  key: "isNavbarCollapsed",
  default: true
})

export const accessToken = atom<{ token: string | null, expireAt: string | null }>({
  key: "accessToken",
  default: { token: null, expireAt: null }
})
