import { useEffect, useState, useRef } from "react";
import { showNotification } from "@mantine/notifications";

type UseGeolocation = GeolocationSuccess | GeolocationError | GeolocationWaiting

type GeolocationWaiting = {
  status: 0 // Loading
}

type GeolocationSuccess = {
  status: 1 // Success
  latitude: number;
  longitude: number;
  accuracy: number;
  timestamp: number;
  heading?: number;
}

type GeolocationError = {
  status: 2 // Error
  error: string;
}

export function useGeolocation(options: PositionOptions = {}) {
  const [state, setState] = useState<UseGeolocation>({ status: 0 });

  const optionsRef = useRef(options);

  useEffect(() => {
    const onEvent = ({ coords, timestamp }: GeolocationPosition) => {
      setState({
        status: 1,
        timestamp,
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
        ...coords.heading && { heading: coords.heading },
      });
    };

    const onEventError = (error: GeolocationPositionError) => {
      showNotification({
        color: "red",
        title: "Geolocation error",
        message: error.message,
      })
      setState((s) => ({
        status: 2,
        error: error.message,
      }));
    };

    navigator.geolocation.getCurrentPosition(
      onEvent,
      onEventError,
      optionsRef.current
    );

    const watchId = navigator.geolocation.watchPosition(
      onEvent,
      onEventError,
      optionsRef.current
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return state;
}
