import { getRecoil, setRecoil } from "recoil-nexus";
import { accessToken } from "../../store";
import axios, { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { environment } from "../../environments/environment";
import { showNotification } from "@mantine/notifications";
import { DateTime } from "luxon";

const client = axios.create({
  baseURL: `${environment.API_URL}/api`,
})

const getToken = async () => {
  return new Promise((resolve, reject) => {
    const checkToken = () => {
      const auth = getRecoil(accessToken)
      if (auth.token && auth.expireAt) {
        if (DateTime.utc() < DateTime.fromISO(auth.expireAt, { zone: "utc" })) {
          resolve(auth.token)
        } else {
          setRecoil(accessToken, v =>
            DateTime.utc() < DateTime.fromISO(v.expireAt, { zone: "utc" }) ? v : { expireAt: null, token: null }
          )
        }
      } else {
        setTimeout(checkToken, 100);
      }
      setTimeout(reject, 3000);
    };
    checkToken();
  });
};

client.interceptors.request.use(async (req) => {
  const token = await getToken()
  req.headers.setAuthorization(`Bearer ${token}`)
  return req;
})

interface ErrorResponse {
  statusCode: HttpStatusCode,
  message: string,
  error: string
}

client.interceptors.response.use((res: AxiosResponse) => res, (error: AxiosError<ErrorResponse>) => {
  if(error.response) {
    const { data } = error.response;
    showNotification({
      title: data.error,
      message: data.message,
      autoClose: 3000,
      color: "red"
    })
  } else {
    showNotification({
      message: error.message,
      autoClose: 3000,
      color: "red"
    })
  }
})

export default client;
