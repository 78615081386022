export const ACTIVITY = {
  ALL: "All",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
}

export const ACTIVITY_TYPES = [
  ACTIVITY.ALL,
  ACTIVITY.ACTIVE,
  ACTIVITY.INACTIVE,
] as const