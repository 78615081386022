export const DEFAULT_USER_METADATA = {
  theme: "light",
  mapView: false,
  markerBasic: false,
  markerScale: 1,
  heatmapBy: false,
  heatmapRadius: 50,
  heatmapOpacity: 1,
  filterByDeviceType: false,
  filterByAutoTuning: "All",
  filterByDeviceStatus: "All",
  filterByIsWithinSchedulerGS: "All",
  filterByIsWithinSchedulerC8: "All",
  filterByThresholdParameter: { parameter: false },
  kml: false,
  filterByValve: { active: false, value: { min: 0, max: 100 } },
  filterBySampleDate: { active: false, value: { min: 0, max: 24 } },
  filterByCalibrationDate: { active: false, value: { min: 0, max: 7 } },
  filterByBattery: { active: false, value: { min: 0, max: 100 } },
  filterByInFlow: { active: false, value: { min: 0, max: 1000 } },
  serviceView: false
}
