import axios from "./axios";
import {
  ApisCompanyUser, ApisRoleDefinition,
  CompanyT as Company,
  CreateCompanyRequest,
  CreateUserRequest,
  UpdateCompanyRequest,
} from "@apis/types";
import { User } from "@auth0/types";

export const getAllCompanies = async (): Promise<Company[]> => {
  const res = await axios.get<Company[]>("companies/all")
  return res.data
}

export const getCompany = async (companyID: string): Promise<Company> => {
  const res = await axios.get<Company>(`companies/${companyID}`)
  return res.data;
}

export const createCompany = async (body: CreateCompanyRequest):  Promise<Company> => {
  const res = await axios.post<Company>("companies", body)
  return res.data;
}

export const updateCompany = async (companyID: string, body: UpdateCompanyRequest):  Promise<Company> => {
  const res = await axios.put<Company>(`companies/${companyID}`, body)
  return res.data;
}

export const deleteCompany = async (companyID: string): Promise<Company> => {
  const res = await axios.delete<Company>(`companies/${companyID}`)
  return res.data;
}

export const generateAPIToken = async (companyID: string): Promise<{ token: string }> => {
  const res = await axios.get<{ token: string }>(`companies/${companyID}/token`)
  return res.data;
}

export const getCompanyUsers = async (companyID: string): Promise<ApisCompanyUser[]> => {
  const res = await axios.get<ApisCompanyUser[]>(`companies/${companyID}/users`)
  return res.data;
}

export const getCompanyAdmins = async (companyID: string): Promise<ApisRoleDefinition[]> => {
  const res = await axios.get<ApisRoleDefinition[]>(`companies/${companyID}/roles`)
  return res.data;
}

export const inviteUser = async (companyID: string, body: CreateUserRequest): Promise<ApisCompanyUser> => {
  const res = await axios.post<ApisCompanyUser>(`companies/${companyID}/users`, body)
  return res.data;
}

export const deleteUserFromCompany = async (companyID: string, userid: string): Promise<User> => {
  const res = await axios.delete<User>(`companies/${companyID}/users/${userid}`)
  return res.data;
}

export const addCompanyAdmin = async (companyID: string, userid: string): Promise<ApisRoleDefinition> => {
  const res = await axios.put<ApisRoleDefinition>(`companies/${companyID}/company-admin/${userid}`)
  return res.data;
}

export const revokeCompanyAdmin = async (companyID: string, userid: string): Promise<User> => {
  const res = await axios.delete<User>(`companies/${companyID}/company-admin/${userid}`)
  return res.data;
}
