import { useNavigate, useRouteError } from "react-router-dom";
import { Paper, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ErrorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const error = useRouteError();
  console.error(error);
  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 5000)
  }, [navigate]);

  return <Paper shadow="sm" p="lg">
    <Text>{ t("ERROR_PAGE_CONTENT") }</Text>
  </Paper>
}

export default ErrorPage;
