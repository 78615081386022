import { useEffect, useMemo } from "react";
import { LogEvent } from "@apis/types";
import { useRecoilState } from "recoil";
import { accessToken } from "../store";
import { DateTime } from "luxon";

interface Options {
  url: string;
  onMessage: (event: LogEvent) => void;
}

export const useEventSource = ({ url, onMessage }: Options) => {
  const [auth, setAuth] = useRecoilState(accessToken);

  useEffect(() => {
    if (auth.token && DateTime.utc() > DateTime.fromISO(auth.expireAt, { zone: "utc" })) {
      setAuth({ token: null, expireAt: null })
    }
  }, [auth]);

  const eventSource = useMemo(() => auth.token && new EventSource(`${url}?token=${auth.token}`), [url, auth.token]);
  if (eventSource) {
    eventSource.onmessage = (event: MessageEvent) => onMessage(JSON.parse(event.data));
  }
  useEffect(() => {
    return () => {
      eventSource && eventSource.close();
    };
  }, [eventSource]);
  return eventSource;
};
