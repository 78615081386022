export const LANDFILL_TYPE = {
  DIRECT_USE: "Direct Use",
  GAS_TO_ELECTRIC: "Gas-to-Electric",
  HIGH_BTU: "High-Btu",
  NON_ENERGY: "Non-Energy"
} as const
export const LANDFILL_TYPES = [
  LANDFILL_TYPE.DIRECT_USE,
  LANDFILL_TYPE.GAS_TO_ELECTRIC,
  LANDFILL_TYPE.HIGH_BTU,
  LANDFILL_TYPE.NON_ENERGY
] as const



export const LANDFILL_REGION = {
  NORTH_AMERICA: "North America"
} as const
export const LANDFILL_REGIONS = [
  LANDFILL_REGION.NORTH_AMERICA
] as const



export const FLOW_CALCULATION_TYPE = {
  CFM: "CFM",
  SCFM: "SCFM"
} as const
export const FLOW_CALCULATION_TYPES = [
  FLOW_CALCULATION_TYPE.CFM,
  FLOW_CALCULATION_TYPE.SCFM
] as const


export const METHANE_FLOW_CALCULATION_TYPE = {
  METHANE_FLOW: "methaneFlow",
  NORMALIZED_FLOW: "normalizedFlow"
} as const
export const METHANE_FLOW_CALCULATION_TYPES = [
  METHANE_FLOW_CALCULATION_TYPE.METHANE_FLOW,
  METHANE_FLOW_CALCULATION_TYPE.NORMALIZED_FLOW
] as const
