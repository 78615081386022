import { DeviceStatusOption, DeviceStatusSeverityMap, DeviceType } from "@apis/types";
import { DEVICE_TYPE } from "./device";

export const DEVICE_STATUS_OPTION = {
  OKAY: "Okay",
  FIELD_ISSUE: "Field Issue",
  CALIBRATION_DUE: "Calibration Due",
  CHECK_FILTERS: "Check Filters",
  REVIEW_DATA: "Review Data",
  DEVICE_ISSUE: "Device Issue",
  OFFLINE_DUE_TO_SITE: "Offline due to site"
} as const
export const DEVICE_STATUS_OPTIONS = [
  DEVICE_STATUS_OPTION.OKAY,
  DEVICE_STATUS_OPTION.FIELD_ISSUE,
  DEVICE_STATUS_OPTION.CALIBRATION_DUE,
  DEVICE_STATUS_OPTION.CHECK_FILTERS,
  DEVICE_STATUS_OPTION.REVIEW_DATA,
  DEVICE_STATUS_OPTION.DEVICE_ISSUE,
  DEVICE_STATUS_OPTION.OFFLINE_DUE_TO_SITE
] as const

export const DEVICE_STATUS_OPTIONS_BY_DEVICES: Partial<Record<DeviceType, DeviceStatusOption[]>> = {
  [DEVICE_TYPE.WEATHER_STATION]: [
    DEVICE_STATUS_OPTION.OKAY,
    DEVICE_STATUS_OPTION.DEVICE_ISSUE,
    DEVICE_STATUS_OPTION.REVIEW_DATA
  ]
}


export const DEVICE_STATUS_SEVERITY = {
  CRITICAL: 1,
  HIGH: 2,
  MEDIUM: 3,
  LOW: 4,
} as const
export const DEVICE_STATUS_SEVERITIES = [
  DEVICE_STATUS_SEVERITY.CRITICAL,
  DEVICE_STATUS_SEVERITY.HIGH,
  DEVICE_STATUS_SEVERITY.MEDIUM,
  DEVICE_STATUS_SEVERITY.LOW,
] as const

export const DEVICE_STATUS_SEVERITY_MAP: DeviceStatusSeverityMap = {
  1: { label: "Critical", color: "#F15932" },
  2: { label: "High", color: "#F15932" },
  3: { label: "Medium", color: "#F6A727" },
  4: { label: "Low", color: "#19009D" }
}
