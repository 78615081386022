import { UnstyledButton, Group, Avatar, Text, createStyles, Menu, Skeleton, Stack, Indicator, Badge } from "@mantine/core";
import { faRightFromBracket, faUser, faExclamation, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { deviceLayout, isNavbarCollapsed } from "../../store";
import { useEffect, useMemo } from "react";
import { environment } from "../../environments/environment";
import { useGetCompaniesSelf } from "../../query";

const RedirectToLogin = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
      loginWithRedirect({
        authorizationParams: {
          audience: environment.AUTH0_AUDIENCE,
          scope: "openid profile email",
        },
        appState: {
          returnTo: window.location.pathname + window.location.search,
        },
      });
  }, [loginWithRedirect]);
  return null;
};

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  label: {
    textAlign: "center"
  }
}));

const UserInfo = () => {
  const { classes } = useStyles();
  const { user, isLoading, logout } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const layout = useRecoilValue(deviceLayout);
  const isTablet = useMemo(() => layout === "tablet", [layout]);
  const navbarCollapsed = useRecoilValue(isNavbarCollapsed);
  const { data: companies } = useGetCompaniesSelf({ staleTime: 30 })

  const notifications = useMemo(() => companies && companies.reduce((acc, cmp) => {
    if (!cmp.active) acc++
    return acc;
  }, 0), [companies])

  if(isLoading) return <UnstyledButton className={classes.user}>
    <Group sx={{ padding: 0, height: 40 }}>
      <Skeleton height={32} width={32} />
      {
        !isTablet && <div style={{ flex: 1 }}>
          <Skeleton height={12} width="60%" />
          <Skeleton height={8} mt={10} width="90%" />
        </div>
      }
    </Group>
  </UnstyledButton>

  if(!user) return <RedirectToLogin />

  return (
    <Menu
      shadow="md"
      position={(isTablet || navbarCollapsed) ? "top-start" : "top-end"}
      width="auto"
      withArrow
      arrowOffset={28}
    >
      <Menu.Target>
        <UnstyledButton className={classes.user}>
          <Group>
            <Indicator inline disabled={!notifications} label={<FontAwesomeIcon icon={faExclamation} />} size={20} color={"red"}>
              <Avatar src={user.picture} size={32} />
            </Indicator>
            {!isTablet && !navbarCollapsed && <div style={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {user.name}
              </Text>
              <Text color="dimmed" size="xs">
                {user.email}
              </Text>
            </div>}
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown miw={200}>
        {
          (isTablet || navbarCollapsed) && <Stack spacing={0} px="sm" py="xs">
            <Text size="md">{user.name}</Text>
            <Text size="sm" color="dimmed">{user.email}</Text>
            <Menu.Divider />
          </Stack>
        }
        <Menu.Item icon={<FontAwesomeIcon icon={faUser} />} onClick={() => navigate("/account")} >
          { t("ACCOUNT") }
        </Menu.Item>
        <Menu.Item icon={<FontAwesomeIcon icon={faBuilding} />} onClick={() => navigate("/companies")} >
          <Group align="center" position="apart" noWrap>
            <Text>{ t("COMPANIES") }</Text>
            { notifications && <Badge variant="filled" color="red" size="sm">{notifications} invite{notifications !== 1 ? "s" : ""}</Badge> }
          </Group>
        </Menu.Item>
        <Menu.Item icon={<FontAwesomeIcon icon={faRightFromBracket} />} onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })}>
          { t("LOGOUT") }
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label className={classes.label}>v{ environment.APP_VERSION }</Menu.Label>
      </Menu.Dropdown>
    </Menu>
  );
}

export default UserInfo;
