import axios from "./axios";
import { CreateDeviceGroupRequest, CurrentData, DeviceGroupT, LandfillCharts } from "@apis/types";

export const getDeviceGroup = async (groupID: string): Promise<DeviceGroupT> => {
  const res = await axios.get<DeviceGroupT>(`/device-groups/${groupID}`);
  return res.data;
}

export const getDeviceGroupsOfLandfill = async (landfillID: string): Promise<DeviceGroupT[]> => {
  if(!landfillID) return [];
  const res = await axios.get<DeviceGroupT[]>(`/landfills/${landfillID}/device-groups`);
  return res.data;
}

export const createDeviceGroup = async (landfillID: string, body: CreateDeviceGroupRequest): Promise<DeviceGroupT> => {
  const res = await axios.post<DeviceGroupT>(`/device-groups/${landfillID}`, body);
  return res.data;
}

export const addDeviceToGroup = async (groupID: string, deviceID: string): Promise<DeviceGroupT> => {
  const res = await axios.put<DeviceGroupT>(`/device-groups/${groupID}/device/${deviceID}`);
  return res.data;
}

export const sampleAllDevicesInGroup = async (groupID: string): Promise<{ failed: string[] }> => {
  const res = await axios.post<{ failed: string[] }>(`/device-groups/${groupID}/sample-all`);
  return res.data;
}

export const removeDeviceFromGroup = async (groupID: string, deviceID: string): Promise<DeviceGroupT> => {
  const res = await axios.delete<DeviceGroupT>(`/device-groups/${groupID}/device/${deviceID}`);
  return res.data;
}

export const deleteDeviceGroup = async (groupID: string): Promise<{ message: string }> => {
  const res = await axios.delete<{ message: string }>(`/device-groups/${groupID}`);
  return res.data;
}

export const getDeviceGroupCurrentData = async (groupID: string): Promise<CurrentData[]> => {
  const res = await axios.get<CurrentData[]>(`/device-groups/${groupID}/current-data`);
  return res.data;
}

export const getDeviceGroupCharts = async (groupID: string, start: string, end: string): Promise<LandfillCharts> => {
  const res = await axios.get<LandfillCharts>(`/device-groups/${groupID}/charts`, { params: { start, end } });
  return res.data;
}
