import { DefaultOptions, QueryClient } from "@tanstack/react-query";

const defaultReactQueryOptions: DefaultOptions = {
  queries: {
    retry: false,
  }
}

export const queryClient = new QueryClient({ defaultOptions: defaultReactQueryOptions });

export * from "./alertParameters"
export * from "./cloudConnector"
export * from "./company"
export * from "./controlAlgorithmSettings"
export * from "./device"
export * from "./deviceGroup"
export * from "./landfill"
export * from "./user"
export * from "./admin"
