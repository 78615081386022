// Do not forget to update export/import types when add new alert parameter
import { AlertParametersAttributes, AlertParametersDefaultParameters } from "@apis/types";

export const ALERT_PARAMETER_KEY = {
  "CH4": "CH4",
  "CO2": "CO2",
  "O2": "O2",
  "BAL": "bal",
  "STATIC_PRESSURE": "staticPressure",
  "SYSTEM_PRESSURE": "systemPressure",
  "DIFF_PRESSURE": "diffPressure",
  "FLOW": "flow",
  "GAS_TEMP": "gasTemp"
} as const
export const ALERT_PARAMETER_KEYS = [
  ALERT_PARAMETER_KEY.CH4,
  ALERT_PARAMETER_KEY.CO2,
  ALERT_PARAMETER_KEY.O2,
  ALERT_PARAMETER_KEY.BAL,
  ALERT_PARAMETER_KEY.STATIC_PRESSURE,
  ALERT_PARAMETER_KEY.SYSTEM_PRESSURE,
  ALERT_PARAMETER_KEY.DIFF_PRESSURE,
  ALERT_PARAMETER_KEY.FLOW,
  ALERT_PARAMETER_KEY.GAS_TEMP
] as const

export const ALERT_PARAMETERS_ATTRIBUTES: AlertParametersAttributes = {
  "CH4": {
    "label": "Methane",
    "min": 0,
    "max": 75,
    "unit": "%",
    "step": 0.5
  },
  "CO2": {
    "label": "Carbon Dioxide",
    "min": 0,
    "max": 75,
    "unit": "%",
    "step": 0.5
  },
  "O2": {
    "label": "Oxygen",
    "min": 0,
    "max": 20,
    "unit": "%",
    "step": 0.25,
    "minRange": 0.25
  },
  "bal": {
    "label": "Balance",
    "min": 0,
    "max": 100,
    "unit": "%",
    "step": 0.5
  },
  "staticPressure": {
    "label": "Static Pressure",
    "min": -100,
    "max": 50,
    "unit": "in. wc",
    "step": 0.5
  },
  "systemPressure": {
    "label": "System Pressure",
    "min": -100,
    "max": 50,
    "unit": "in. wc",
    "step": 0.5
  },
  "diffPressure": {
    "label": "Diff. Pressure",
    "min": 0,
    "max": 100,
    "unit": "in. wc",
    "step": 0.5
  },
  "flow": {
    "label": "Flow",
    "min": 0,
    "max": 50000,
    "unit": "CFM",
    "step": 1
  },
  "gasTemp": {
    "label": "Gas Temp",
    "min": -25,
    "max": 150,
    "unit": "degF",
    "step": 1
  }
}
export const ALERT_PARAMETERS_DEFAULT_PARAMETERS: AlertParametersDefaultParameters = {
  "CH4": { min: 45, max: 60 },
  "CO2": { min: 35, max: 45 },
  "O2": { min: 0, max: 5 },
  "bal": { min: 0, max: 15 },
  "staticPressure": { min: -50, max: 0 },
  "systemPressure": { min: -100, max: 0 },
  "diffPressure": { min: 0, max: 30 },
  "flow": { min: 0, max: 50000 },
  "gasTemp": { min: 0, max: 130 }
}
